<template>
  <InstantSearch
    hasFilters
    class="client-search-secondary-filters__wrapper">
    <form
      ref="form"
      @submit.prevent="$emit('submit')"
      novalidate
      class="client-search-secondary-filters__container">
      <header class="client-search-secondary-filters__header">
        <Heading
          :tag="'h3'"
          :level="'h3'">
          {{ $t('artist.dashboard.client.dashboard.filter.title') }}
        </Heading>
        <TextLink
          @click="clearFilters"
          :tag="'button'"
          type="button">
          {{ $t('artist.dashboard.client.dashboard.filter.resetCta') }}
        </TextLink>
      </header>

      <div>
        <Select
          v-model="activityFilter"
          :options="activityOptions"
          :label="$t('artist.dashboard.client.dashboard.search.categories')"
          isResetValueAfterSelect
          id="activity-filter"
          class="mb-sm">
        </Select>
        <ul class="client-search-secondary-filters__activity-list">
          <li
            v-for="(activity, index) in filters.activity"
            :key="`activity-${index}`">
            <Tag :color="'light'">
              {{ $t(getActivityTranslation(activity)) }}
              <template #actions>
                <Icon
                  @click="handleOptionDelete('activity', activity)"
                  :variant="'cross-outline'"
                  :size="'xxs'"
                  :tag="'button'"
                  type="button"
                  class="ml-xs">
                </Icon>
              </template>
            </Tag>
          </li>
        </ul>
      </div>

      <div>
        <Select
          v-model="languageFilter"
          :options="languageOptions"
          :label="$t('artist.dashboard.menu.header.editPerformance.languages.subTitle')"
          isResetValueAfterSelect
          id="activity-filter"
          class="mb-sm">
        </Select>
        <ul class="client-search-secondary-filters__activity-list">
          <li
            v-for="(language, index) in filters.language"
            :key="`language-${index}`">
            <Tag :color="'light'">
              {{ $t(languageOptions.find(option => option.value === language).label) }}
              <template #actions>
                <Icon
                  @click="handleOptionDelete('language', language)"
                  :variant="'cross-outline'"
                  :size="'xxs'"
                  :tag="'button'"
                  type="button"
                  class="ml-xs">
                </Icon>
              </template>
            </Tag>
          </li>
        </ul>
      </div>

      <RangeSelector
        v-model="pricingFilter"
        :numberOfSteps="0"
        :label="$t('common.price')"
        min="0"
        max="5000"
        step="1"
        id="pricing">
      </RangeSelector>

      <Checkbox
        v-model="childrenPermission"
        :label="$t('artist.dashboard.client.dashboard.search.childrenAllowed')"
        id="children-permission">
      </Checkbox>

      <ais-state-results
        v-if="!$mediaQueries.isDesktop"
        class="mt-auto">
        <template #default="{ results: { hits } }">
          <Button
            isFluid
            type="submit">
            {{ $tc('artist.dashboard.client.dashboard.search.seeArtists', hits.length, { hitsCounter: hits.length }) }}
          </Button>
        </template>
      </ais-state-results>
    </form>
  </InstantSearch>
</template>

<script>

import {
  mapState,
  mapMutations,
}                                     from 'vuex';
import throttle                       from 'lodash.throttle';

import Icon                           from '../../../components/atoms/Icon/a-Icon.vue';
import Tag                            from '../../../components/atoms/Tag/a-Tag.vue';
import Heading                        from '../../../components/atoms/Heading/a-Heading.vue';
import Button                         from '../../../components/atoms/Button/a-Button.vue';
import Checkbox                       from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import Select                         from '../../../components/atoms/Select/a-Select.vue';
import TextLink                       from '../../../components/atoms/TextLink/a-TextLink.vue';
import RangeSelector                  from '../../../components/molecules/RangeSelector/m-RangeSelector.vue';
import InstantSearch                  from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import ACTIVITY_OPTIONS               from '../../../constants/activity-options.js';
import CHILDREN_FILTER_OPTIONS        from '../../../constants/children-permission-options.js';
import LANGUAGE_OPTIONS               from '../../../constants/languages.js';
import {
  getActivityTranslation,
}                                     from '../../../utils/i18nUtils.js';

export default {
  name: 'o-ClientSearchSecondaryFilters',
  components: {
    Icon,
    Tag,
    Heading,
    Button,
    Select,
    TextLink,
    Checkbox,
    RangeSelector,
    InstantSearch,
  },
  data: () => ({
    activityOptions: ACTIVITY_OPTIONS,
    languageOptions: LANGUAGE_OPTIONS,
    childrenFilterOptions: CHILDREN_FILTER_OPTIONS,
    selectedPricing: 5000,
    throttledSetPricingFilter: () => {},
  }),
  created() {
    this.selectedPricing = this.filters?.pricing ?? 5000;
    this.throttledSetPricingFilter = throttle(
      (value) => this.setClientFilter({ name: 'pricing', payload: value }),
      2000,
    );
  },
  computed: {
    ...mapState('SearchArtist', ['filters']),
    languageFilter: {
      get() {
        return '';
      },
      set(language) {
        this.setClientFilter({
          name: 'language',
          payload: this.filters.language.some(languageFilter => languageFilter === language)
            ? this.filters.language.filter(languageFilter =>  languageFilter !== language)
            : [...this.filters.language, language],
        });
      },
    },
    activityFilter: {
      get() {
        return '';
      },
      set(activity) {
        this.setClientFilter({
          name: 'activity',
          payload: this.filters.activity.some(activityFilter => activityFilter === activity)
            ? this.filters.activity.filter(activityFilter =>  activityFilter !== activity)
            : [...this.filters.activity, activity],
        });
      },
    },
    childrenPermission: {
      get() {
        // TODO: Change to constant
        return this.filters.childrenPermission.includes('CHILDREN_ALLOWED');
      },
      set(value) {
        const payload = this.childrenFilterOptions
          .filter(({ permission }) => permission === value)
          .map(({ value }) => value);

        this.setClientFilter({ name: 'childrenPermission', payload });
      },
    },
    pricingFilter: {
      get() {
        return this.selectedPricing;
      },
      set(value) {
        this.selectedPricing = value;
        this.throttledSetPricingFilter(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      setClientFilter: 'SearchArtist/SET_ACTIVE_FILTER',
      clearFilters: 'SearchArtist/CLEAR_ACTIVE_SECONDARY_FILTERS',
      toggleMobileFiltersType: 'Layout/TOGGLE_MOBILE_FILTERS_TYPE',
    }),
    getActivityTranslation,
    handleOptionDelete(filterName, optionToDelete) {
      this.setClientFilter({
        name: filterName,
        payload: this.filters[filterName]?.filter(option => option !== optionToDelete) ?? [],
      });
    },
  },
}

</script>

<style lang="scss">

.client-search-secondary-filters {
  &__wrapper {
    height: 100%;
    padding: var(--space-md);
    padding-top: calc(1.25 * var(--space-base));
    background-color: var(--color-white);

    @media screen and ($desktop) {
      position: sticky;
      top: var(--space-header-height);
      height: auto;
    }
  }

  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);

    > * {
      flex: 0 1 0;
    }
  }

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0 calc(-1 * var(--space-md));
    padding: var(--space-md);
    padding-top: 0;

    a {
      display: flex;
    }
  }

  &__activity-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }
}

</style>
