<template>
  <InstantSearch hasFilters>
    <section class="client-search-artist-list__wrapper">
      <header class="client-search-artist-list__header">
        <ais-stats>
          <template #default="{ nbHits }">
            <Heading
              :tag="'h3'"
              :level="'h3'"
              :color="'gradient'"
              class="client-search-artist-filters__results-cta">
                {{ nbHits }} {{ $t('common.artists') }}
            </Heading>
            <Paragraph>
              {{ $t('artist.dashboard.client.dashboard.search.hits') }}
            </Paragraph>
          </template>
        </ais-stats>
        <ClientSearchArtistSorting
          v-if="$mediaQueries.isDesktop"
          class="ml-auto">
        </ClientSearchArtistSorting>
      </header>

      <ais-hits class="pb-lg">
        <template #default="{ items }">
          <VirtualizedList
            :items="items"
            :incrementPaginationBy="20">
            <template #default="{ virtualizedListItems: artists }">
              <ul class="client-search-artist-list__artists-list">
                <li v-if="!artists || (artists && !artists.length)">
                  <Paragraph
                    isTextCentered
                    isBold
                    class="my-xl">
                    {{ $t('artist.dashboard.client.dashboard.search.noResults') }}
                  </Paragraph>
                </li>
                <template v-else>
                  <li
                    @click="handleSelectArtist(artist)"
                    v-for="artist in artists"
                    :key="`available-artist-${artist.objectID}`"
                    class="mb-xs">
                    <router-link :to="{ name: 'ArtistProfilePublic', params: { id: artist.objectID } }">
                      <ArtistProfileCard
                        :artist="artist"
                        :isShowCustomQuoteHint="geolocalisationFilter.getRankingInfo">
                      </ArtistProfileCard>
                    </router-link>
                  </li>
                </template>
              </ul>
            </template>
          </VirtualizedList>
        </template>
      </ais-hits>
    </section>
  </InstantSearch>
</template>

<script>

import {
  mapGetters,
  mapMutations,
}                                     from 'vuex';

import Heading                        from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import InstantSearch                  from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import VirtualizedList                from '../../../components/renderless/VirtualizedList/r-VirtualizedList.vue';
import ArtistProfileCard              from './o-ArtistProfileCard.vue';
import ClientSearchArtistSorting      from './o-ClientSearchArtistSorting.vue';


export default {
  name: 'o-ClientSearchArtistList',
  components: {
    Heading,
    Paragraph,
    InstantSearch,
    VirtualizedList,
    ArtistProfileCard,
    ClientSearchArtistSorting,
  },
  computed: {
    ...mapGetters({
      geolocalisationFilter: 'SearchArtist/GEOLOCALISATION_FILTER',
    }),
  },
  methods: {
    ...mapMutations({
      setIsCustomQuoteNeeded: 'SearchArtist/SET_IS_CUSTOM_QUOTE_NEEDED',
      setArtistLocation: 'SearchArtist/SET_CURRENT_ARTIST_LOCATION',
    }),
    handleSelectArtist(artist) {
      // Matched geolocation distance is in meters but artist locationRange is in kilometers
      const isCustomQuote = Boolean(
        (artist._rankingInfo?.matchedGeoLocation?.distance / 100) > artist.locationRange
      );

      this.setArtistLocation(artist._geoloc || { lat: 0, lng: 0 });
      this.setIsCustomQuoteNeeded(isCustomQuote);
    },
  },
}

</script>

<style lang="scss">

.client-search-artist-list {
  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__header {
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__search {
    z-index: 2;
    position: sticky;
    top: 0;
    padding-bottom: var(--space-lg);
    background-color: var(--color-grey-background);
  }

  &__artists-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-base);
  }
}

</style>