<template>
  <form
    @submit.prevent="$emit('submit')"
    ref="form"
    novalidate
    class="client-search-primary-filters__wrapper">
    <ClientSearchBar></ClientSearchBar>

    <InstantSearch
      hasFilters
      class="mt-auto">
      <ais-state-results>
        <template #default="{ results: { hits } }">
          <Button
            isFluid
            type="submit">
            {{ $tc('artist.dashboard.client.dashboard.search.seeArtists', hits.length, { hitsCounter: hits.length }) }}
          </Button>
        </template>
      </ais-state-results>
    </InstantSearch>
  </form>
</template>

<script>

import Button                   from '../../../components/atoms/Button/a-Button.vue';
import InstantSearch            from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import ClientSearchBar          from './o-ClientSearchBar.vue';


export default {
  name: 'o-ClientSearchPrimaryFilters',
  components: {
    Button,
    ClientSearchBar,
    InstantSearch,
  },
}

</script>

<style lang="scss">

.client-search-primary-filters {
  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
    padding: var(--space-md);
    background-color: var(--color-white);

    > * {
      flex: 0 1 auto;
    }
  }
}

</style>
