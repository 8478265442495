export default [
  {
    label: 'artist.dashboard.client.dashboard.sort.ascending',
    value: process.env.VUE_APP_ALGOLIA_PRICE_ASC_INDEX,
  },
  {
    label: 'artist.dashboard.client.dashboard.sort.descending',
    value: process.env.VUE_APP_ALGOLIA_PRICE_DESC_INDEX,
  },
];
