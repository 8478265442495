<template>
  <MainLayout :class="[
      'main-layout--client-dashboard',
      { [`main-layout--client-dashboard-${$route.meta.layout}`]: Boolean($route.meta.layout) },
    ]"
  >
    <AppBreadcrumb
      v-if="breadcrumbData"
      :levels="breadcrumbData"
      class="py-md">
    </AppBreadcrumb>

    <router-view
      :user="user"
      name="default"
      class="pt-md pb-xl">
    </router-view>
    <article
      v-if="$mediaQueries.isDesktop && $route.name === 'ClientDashboard'"
      class="client-dashboard__sticky">
      <router-view name="filters"></router-view>
    </article>
    <router-view
      v-if="!$mediaQueries.isDesktop"
      @sort="isSortingOpen = true"
      name="drawer">
    </router-view>

    <transition
      name="vertical-slide-full-down-up"
      appear>
      <template v-if="!$mediaQueries.isDesktop">
        <ClientDashboardSorting
          v-if="isSortingOpen"
          @sort="isSortingOpen = false"
        />
        <ClientDashboardFilter v-else-if="currentMobileFiltersType"/>
      </template>
    </transition>
  </MainLayout>
</template>

<script>

import { mapState }               from 'vuex';

import store                      from '../../../store/index.js';
import MainLayout                 from '../../../components/layout/MainLayout/l-MainLayout.vue';
import AppBreadcrumb              from '../../../components/molecules/AppBreadcrumb/m-AppBreadcrumb.vue';
import ClientDashboardSorting     from '../components/o-ClientSearchArtistSorting.vue';
import ClientDashboardFilter      from '../components/o-ClientDashboardFilter.vue';


export default {
  name: 'p-ClientDashboard',
  components: {
    MainLayout,
    AppBreadcrumb,
    ClientDashboardSorting,
    ClientDashboardFilter,
  },
  data: () => ({
    isFilterOpen: false,
    isSortingOpen: false,
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapState('Layout', ['currentMobileFiltersType']),
    breadcrumbData() {
      return this.$route.meta.breadcrumb?.map(({ label, ...level }) => ({
        ...level,
        label: typeof label === 'function' ? label(store) : label,
      }));
    },
  },
}

</script>


<style lang="scss">

.client-dashboard {
  &__sticky {
    @media screen and ($desktop) {
      grid-column: 1 / 2;
      grid-row: 1 / -1;
      background-color: var(--color-white);
    }
  }
}

</style>
