<template>
  <div class="radio__wrapper">
    <div :class="[inputWrapperStyle, 'radio__input-wrapper']">
      <input
        v-bind="$attrs"
        v-on="{
          ...$listeners,
          input: handleInput,
        }"
        :value="value"
        :checked="isChecked"
        :name="name"
        :id="id"
        class="radio__input"
        type="radio"/>
    </div>

    <label :for="id" class="radio__label">
      <!-- @slot Custom label  -->
      <slot v-bind="{ value }">{{ label }}</slot>
    </label>
  </div>
</template>

<script>


export default {
  name: 'a-Radio',
  inheritAttrs: false,
  data: () => ({
    localErrors: [],
  }),
  model: {
    prop: 'parentModel',
  },
  props: {
    parentModel: {
      type: null,
      required: true
    },
    value: {
      type: null
    },
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    isChecked() {
      return this.parentModel === this.value;
    },
    inputWrapperStyle() {
      return { 'radio__input-wrapper--checked': this.isChecked }
    }
  },
  methods: {
    handleInput() {
      /**
       * Emits the value bound to 'value' prop
       *
       * @event input
       * @type {any}
       */
      this.$emit('input', this.value);
    },
  }
}

</script>

<style lang="scss">

.radio {
  &__wrapper {
    display: flex;
    align-items: center;
    font-family: var(--font-stack-secondary);
    cursor: pointer;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    border-radius: var(--rounded-circle);

    &:after {
      content: '';
      position: absolute;
      height: 20px;
      width: 20px;
      border: 1px solid var(--color-grey-semi);
      border-radius: var(--rounded-circle);
      background-color: var(--color-white);
      pointer-events: none;
    }

    &--checked {
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid var(--color-black);
        border-radius: var(--rounded-circle);
      }

      &:after {
        height: 10px;
        width: 10px;
        top: 5px;
        left: 5px;
        border: 2px solid var(--color-black);
        background-color: var(--color-black);
      }
    }
  }

  &__input {
    height: 20px;
    width: 20px;
    pointer-events: all;
  }

  &__label {
    flex: 1 1 auto;
    margin-left: var(--space-sm);
    font-size: var(--text-sm);
    font-weight: var(--font-medium);
    line-height: var(--lineheight-lg);
  }
}

</style>
