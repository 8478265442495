<template>
  <article
    @focusout="event => isSortOpen = event.currentTarget.contains(event.relatedTarget || event.target)"
    class="client-search-artist-sorting__wrapper">
    <header>
      <Icon
        v-if="$mediaQueries.isDesktop"
        @click.stop="isSortOpen = !isSortOpen"
        :variant="'sort-outline'"
        :tag="'button'"
        type="button">
      </Icon>
      <Heading
        v-else
        :tag="'h3'"
        :level="'h3'">
        {{ $t('artist.dashboard.client.dashboard.search.sort') }}
      </Heading>
    </header>

    <form
      v-if="!$mediaQueries.isDesktop || isSortOpen"
      @submit.prevent="$emit('sort')"
      novalidate
      class="client-search-artist-sorting__form-wrapper">
      <ul class="client-search-artist-sorting__sort-list">
        <li
          v-for="(option, index) in sortOptions"
          :key="`sort-option-${index}`">
          <Radio
            v-model="selectedSortOption"
            @click="handleSortOptionSelected"
            :value="option.value"
            :id="`sort-option-${option.value}`"
            :name="'artist-search-sort'"
            :label="$t(option.label)">
          </Radio>
        </li>
      </ul>

      <InstantSearch
        v-if="!$mediaQueries.isDesktop"
        hasFilters>
        <ais-state-results>
          <Button
            slot-scope="{ results: { hits } }"
            isFluid
            class="client-search-artist-filters__results-cta"
            type="submit">
            {{ $tc('artist.dashboard.client.dashboard.search.seeArtists', hits.length, { hitsCounter: hits.length }) }}
          </Button>
        </ais-state-results>
      </InstantSearch>
    </form>
  </article>
</template>

<script>

import {
  mapState,
  mapMutations,
}                         from 'vuex';

import Icon               from '../../../components/atoms/Icon/a-Icon.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Radio              from '../../../components/atoms/Radio/a-Radio.vue';
import Button             from '../../../components/atoms/Button/a-Button.vue';
import InstantSearch      from '../../../components/molecules/InstantSearch/m-InstantSearch.vue';
import sortOptions        from '../constants/c-sort-options.js';


export default {
  name: 'o-ClientSearchArtistSorting',
  components: {
    Icon,
    Heading,
    Radio,
    Button,
    InstantSearch,
  },
  data: () => ({
    sortOptions,
    isSortOpen: false,
  }),
  computed: {
    ...mapState('SearchArtist', ['sortPricingBy']),
    selectedSortOption: {
      get() {
        return this.sortPricingBy;
      },
      set(sortPricingBy) {
        this.setSortingMode(sortPricingBy);
      }
    }
  },
  mounted() {
    document.body.addEventListener('click', this.handleClick);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.handleClick);
  },
  methods: {
    ...mapMutations({ setSortingMode: 'SearchArtist/SET_SORTING_MODE' }),
    handleSortOptionSelected(event) {
      if (event.target.value === this.sortPricingBy) this.setSortingMode('');
    },
    handleClick(event) {
      if (!this.$mediaQueries.isDesktop) return;

      this.isSortOpen = this.$el.contains(event.target);
    },
  },
}

</script>

<style lang="scss">

.client-search-artist-sorting {
  &__wrapper {
    z-index: 10;
    position: fixed;
    inset: 0;
    top: initial;
    padding: var(--space-md);
    border-top-right-radius: var(--rounded-xl);
    border-top-left-radius: var(--rounded-xl);
    box-shadow: 0 -4px 10px 5px hsla(0, 0%, 0%, .05);
    background-color: var(--color-white);
    overflow-y: scroll;
    overscroll-behavior: contain;

    @media screen and ($desktop) {
      position: relative;
      box-shadow: none;
      overflow: visible;
      background-color: transparent;
    }
  }

  &__form-wrapper {
    align-self: end;

    @media screen and ($desktop) {
      position: absolute;
      top: 50%;
      right: 50%;
      padding: var(--space-md);
      background-color: var(--color-white);
      border-radius: var(--rounded-lg);
      box-shadow: 0 -4px 10px 5px hsla(0, 0%, 0%, .05);
      transform: translate(25%, 25%);
      white-space: nowrap;
    }
  }

  &__sort-list {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-sm);
    padding: var(--space-md) 0;

    @media screen and ($desktop) {
      padding: 0;
    }
  }
}

</style>