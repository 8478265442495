<template>
  <transition
    name="vertical-slide-half-down-up"
    appear>
    <article class="client-dashboard-filters__wrapper">
      <ClientSearchPrimaryFilters
        v-if="currentMobileFiltersType === 'primary'"
        @submit="toggleMobileFiltersType('')"
      />
      <ClientSearchSecondaryFilters
        v-if="currentMobileFiltersType === 'secondary'"
        @submit="toggleMobileFiltersType('')"
      />
    </article>
  </transition>
</template>

<script>

import {
  mapState,
  mapMutations,
}                                       from 'vuex';

import ClientSearchPrimaryFilters       from './o-ClientSearchPrimaryFilters.vue';
import ClientSearchSecondaryFilters     from './o-ClientSearchSecondaryFilters.vue';


export default {
  name: 'o-ClientDashboardFilter',
  components: {
    ClientSearchPrimaryFilters,
    ClientSearchSecondaryFilters,
  },
  computed: {
    ...mapState('Layout', ['currentMobileFiltersType']),
  },
  methods: {
    ...mapMutations({
      clearFilters: 'SearchArtist/CLEAR_ACTIVE_SECONDARY_FILTERS',
      toggleMobileFiltersType: 'Layout/TOGGLE_MOBILE_FILTERS_TYPE',
    }),
  },
}

</script>

<style lang="scss">

.client-dashboard-filters {
  &__wrapper {
    z-index: 100;
    position: fixed;
    inset: 0;
  }
}

</style>