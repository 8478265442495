<template>
  <section class="artist-profile-card__wrapper">
    <header class="artist-profile-card__header">
      <img
        v-if="artist.urlProfilePictures"
        :src="artist.urlProfilePictures"
        alt=""
        class="artist-profile-card__profile-picture">
      <Icon
        v-else
        :variant="'user-outline'">
      </Icon>
      <Heading
        :level="'h2'"
        :tag="'h3'"
        isOverflow
        class="artist-profile-card__name"
        style="display: inline-block;">
        <!-- TODO -->
        {{ artist.firstname }}
      </Heading>
      <Paragraph class="artist-profile-card__nickname">
        @{{ artist.artisteNicknameGenerated }}
      </Paragraph>
    </header>

    <Paragraph
      isBold
      class="artist-profile-card__activity">
      {{ $t(getActivityTranslation(artist.activity)) }}
    </Paragraph>

    <Heading
      v-if="artist.priceNormal"
      :tag="'h3'"
      :level="'h2'"
      class="artist-profile-card__pricing">
      {{ Math.round(Number(artist.priceNormal.toFixed(2))) }}
      <Paragraph
        :tag="'span'"
        class="mt-xs ml-xxs">
        €
      </Paragraph>
    </Heading>

    <footer class="artist-profile-card__footer">
      <ul
        v-if="tagsToDisplay.length"
        class="artist-profile-card__tags-list">
        <li
          v-for="(tag, index) in tagsToDisplay"
          :key="`tag-${artist.objectID}-${index}`"
          class="artist-profile-card__tags-item">
          <Tag :color="'neutral'">
            {{ tag }}
          </Tag>
        </li>
      </ul>
      <Tag
        v-else
        :color="'neutral'">
        {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.notSet') }}
      </Tag>
      <div class="artist-profile-card__actions">
        <Icon
          :variant="'arrow-right-outline'"
          :size="'xs'"
          :backdropColor="'white'"
          class="mb-xs ml-lg mr-xs">
        </Icon>
      </div>
    </footer>
    <Paragraph
      v-if="isShowCustomQuoteHint && isArtistTooFarAway"
      :size="'sm'"
      class="artist-profile-card__geo-message">
      {{ $t('artist.dashboard.client.booking.details.customQuoteTooFar') }}
    </Paragraph>
  </section>
</template>

<script>

import Icon                           from '../../../components/atoms/Icon/a-Icon.vue';
import Heading                        from '../../../components/atoms/Heading/a-Heading.vue';
import Tag                            from '../../../components/atoms/Tag/a-Tag.vue';
import Paragraph                      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import ACTIVITY_OPTIONS               from '../../../constants/activity-options.js';
import { getActivityTranslation }     from '../../../utils/i18nUtils.js';


export default {
  name: 'o-ArtistProfileCard',
  components: {
    Icon,
    Heading,
    Tag,
    Paragraph,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isShowCustomQuoteHint: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    artistSearchText: '',
    activityOptions: ACTIVITY_OPTIONS,
  }),
  computed: {
    tagsToDisplay() {
      const tags = [];
      const tagsToDisplayCounter = Math.min(this.artist?.tags?.length ?? 0, 5);

      for (let i = 0; i <= tagsToDisplayCounter; i++) {
        this.artist?.tags?.[i] && tags.push(i === 5 ? '...' : this.artist.tags[i]);
      }

      return tags;
    },
    isArtistTooFarAway() {
      // Matched geolocation distance is in meters but artists locationRange is in kilometers
      return Boolean(
        !this.artist._rankingInfo ||
        ((this.artist._rankingInfo?.matchedGeoLocation?.distance / 100) > this.artist.locationRange));
    },
  },
  methods: {
    getActivityTranslation,
  },
}

</script>

<style lang="scss">

.artist-profile-card {
  &__wrapper {
    z-index: 0;
    position: relative;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    grid-template-areas:
      ". header pricing"
      "activity activity activity"
      "footer footer footer"
    ;
    grid-row-gap: var(--space-md);
    align-items: start;
    padding: var(--space-md);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
    box-shadow: 0 0 10px 5px hsla(0, 0%, 0%, .025);

    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: calc(var(--space-base) + var(--space-xl));
      bottom: 2px;
      left: 2px;
      right: 2px;
      background-color: var(--color-grey-background);
      border-radius: var(--rounded-xl);
    }

    @media screen and ($desktop) {
      grid-template-columns: 50% 1fr 20%;
      grid-template-areas:
        "header activity pricing"
        "header activity ."
        "footer footer footer"
      ;
      align-items: center;

      &:before {
        display: none;
      }
    }
  }

  &__header {
    grid-area: header;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 0;

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: 130px 1fr;
      grid-column-gap: var(--space-md);
    }
  }

  &__profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    margin-bottom: var(--space-base);
    border: 1px solid var(--color-grey-light);
    border-radius: var(--rounded-xl);
    background-color: var(--color-grey-light);

    img {
      object-fit: cover;
      border-radius: var(--rounded-xl);
    }

    @media screen and ($desktop) {
      grid-row: span 2;
      height: 130px;
      width: 130px;
      margin-bottom: 0;
    }
  }

  &__name {
    @media screen and ($desktop) {
      align-self: end;
    }
  }

  &__nickname {
    @media screen and ($desktop) {
      align-self: start;
    }
  }

  &__pricing {
    grid-area: pricing;
    justify-self: end;
    font-weight: var(--font-bold);
  }

  &__activity {
    grid-area: activity;
    justify-self: center;
  }

  &__tags-list {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-sm);
  }

  &__footer {
    grid-area: footer;
    display: flex;
    align-items: flex-end;
    row-gap: var(--space-base);
    border-bottom-left-radius: var(--rounded-xl);
    border-bottom-right-radius: var(--rounded-xl);

    @media screen and ($desktop) {
      margin: calc(-1 * var(--space-md));
      margin-top: var(--space-xxs);
      padding: var(--space-md);
      background-color: var(--color-grey-light);
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__geo-message {
    grid-column: 1 / -1;
    color: var(--color-alert);

    @media screen and ($desktop) {
      grid-column: 3 / 4;
      text-align: right;
    }
  }
}

</style>