<template>
  <article class="client-dashboard-bottom-menu__wrapper">
    <button
      @click="toggleMobileFiltersType('secondary')"
      type="button"
      class="client-dashboard-bottom-menu__action">
      <Icon :variant="'filter-outline'"></Icon>
      <Heading
        :tag="'span'"
        :level="'h5'">
        {{ $t('artist.dashboard.client.dashboard.search.filter') }}
      </Heading>
    </button>
    <div class="client-dashboard-bottom-menu__separator"></div>
    <button
      @click="$emit('sort')"
      class="client-dashboard-bottom-menu__action"
      type="button">
      <Icon :variant="'sort-outline'"></Icon>
      <Heading
        :tag="'span'"
        :level="'h5'">
        {{ $t('artist.dashboard.client.dashboard.search.sort') }}
      </Heading>
    </button>
  </article>
</template>

<script>

import {
  mapMutations,
}                   from 'vuex';

import Icon         from '../../../components/atoms/Icon/a-Icon.vue';
import Heading      from '../../../components/atoms/Heading/a-Heading.vue';


export default {
  name: 'o-ClientDashboardBottomMenu',
  components: {
    Icon,
    Heading,
  },
  methods: {
    ...mapMutations({
      toggleMobileFiltersType: 'Layout/TOGGLE_MOBILE_FILTERS_TYPE',
    }),
  },
}

</script>

<style lang="scss">

.client-dashboard-bottom-menu {
  &__wrapper {
    z-index: 10;
    position: sticky;
    bottom: 0;
    margin: 0 calc(-1 * var(--space-md));
    display: flex;
    height: var(--space-header-height);
    background-color: var(--color-white);
    box-shadow: 0 0 3px 2px hsla(0, 0%, 0%, .025);
  }

  &__action {
    flex: 1 0 0;
    display: flex;
    column-gap: var(--space-md);
    justify-content: center;
    align-items: center;
  }

  &__separator {
    height: 30%;
    width: 2px;
    align-self: center;
    background-color: var(--color-grey-light);
  }
}

</style>
