export default [
  {
    label: 'artist.editActivity.childrenDedicated',
    value: 'CHILDREN_DEDICATED',
    algoliaValue: 'CHILDREN_DEDICATED',
    permission: true,
  },
  {
    label: 'artist.editActivity.childrenAllowed',
    value: 'CHILDREN_ALLOWED',
    algoliaValue: 'CHILDREN_ALLOWED',
    permission: true,
  },
  {
    label: 'artist.editActivity.childrenNotAllowed',
    value: 'CHILDREN_UNALLOWED',
    algoliaValue: 'CHILDREN_UNALLOWED',
  },
];
